import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import MichaelWayne from '../assets/images/michaelwayne.jpg';
import Bailey from '../assets/images/bailey.jpg';
import Kevin from '../assets/images/kevin.jpg';
import Jeremy from '../assets/images/pooh.jpg';
import Brett from '../assets/images/brett.jpg';
import Patrick from '../assets/images/patrick.jpg';
import Erin from '../assets/images/erin.jpg';
import Winter from '../assets/images/winter.jpg';
import Abby from '../assets/images/abby.jpg';
import Justin from '../assets/images/justinb.jpg';
import Trey from '../assets/images/trey.jpg';
import Hollon from '../assets/images/hollon.jpg';
import BuddyLee from '../assets/images/buddylee.jpg';
import Jonathan from '../assets/images/hornsby.jpg';
import Jacob from '../assets/images/jacob.jpg';
import Stephen from '../assets/images/stephen.jpg';
import Jenni from '../assets/images/jenni.jpg';
import Derek from '../assets/images/derek.jpg';
import Bill from '../assets/images/bill.jpg';
import Allen from '../assets/images/allendrew.jpg';
import Mark from '../assets/images/markp.jpg';
import Bart from '../assets/images/bart.jpg';
import Wade from '../assets/images/wade.jpg';
import Vicki from '../assets/images/vicki.jpg';
import Alan from '../assets/images/alan.jpg';
import Steven from '../assets/images/steven.jpg';
import Ryan from '../assets/images/ryan.jpg';
import Tiffany from '../assets/images/tiffany.jpg';

export default function MembersPage() {
  return (
    <>
      <SEO title="Members" />
      <div>
        <p>
          Hello! Welcome to the members page. This is where you will find the
          names and pictures of all the members of the "Huddle House Fan Club".
          See the "<Link to="/about">About Us</Link>" section for details.
        </p>
        <p>
          There is really a fine line between a member and a regular fan. You
          are considered a member if you go with us on at least on trip to the
          Huddle House. You are then a member! Fans want to be members, but
          haven't yet gone with us on a trip or just plain out like the Huddle
          House, which anyone, no matter where you are, can do!
        </p>
      </div>
      <h2 className="center" style={{ margin: '1rem 0' }}>
        Members
      </h2>
      <ImageGrid>
        <div>
          <img src={MichaelWayne} alt="Michael Wayne" />
          <ImageCaption>Michael Wayne</ImageCaption>
        </div>
        <div>
          <img src={Bailey} alt="Bailey" />
          <ImageCaption>Bailey</ImageCaption>
        </div>
        <div>
          <img src={Kevin} alt="Kevin" />
          <ImageCaption>Kevin</ImageCaption>
        </div>
        <div>
          <img src={Jeremy} alt="Jeremy" />
          <ImageCaption>Jeremy "Pooh"</ImageCaption>
        </div>
        <div>
          <img src={Brett} alt="Brett" />
          <ImageCaption>Brett</ImageCaption>
        </div>
        <div>
          <img src={Patrick} alt="Patrick" />
          <ImageCaption>Patrick</ImageCaption>
        </div>
        <div>
          <img src={Erin} alt="Erin" />
          <ImageCaption>Erin</ImageCaption>
        </div>
        <div>
          <img src={Winter} alt="Winter" />
          <ImageCaption>Winter</ImageCaption>
        </div>
        <div>
          <img src={Abby} alt="Abby" />
          <ImageCaption>Abby</ImageCaption>
        </div>
        <div>
          <img src={Justin} alt="Justin" />
          <ImageCaption>Justin</ImageCaption>
        </div>
        <div>
          <img src={Trey} alt="Trey" />
          <ImageCaption>Trey</ImageCaption>
        </div>
        <div>
          <img src={Hollon} alt="Hollon" />
          <ImageCaption>Hollon</ImageCaption>
        </div>
        <div>
          <img src={BuddyLee} alt="Buddy Lee" />
          <ImageCaption>Buddy Lee</ImageCaption>
        </div>
        <div>
          <img src={Jonathan} alt="Jonathan" />
          <ImageCaption>Jonathan "Hornsby"</ImageCaption>
        </div>
        <div>
          <img src={Jacob} alt="Jacob" />
          <ImageCaption>Jacob "Cock"</ImageCaption>
        </div>
        <div>
          <img src={Stephen} alt="Stephen" />
          <ImageCaption>Stephen</ImageCaption>
        </div>
        <div>
          <img src={Jenni} alt="Jenni" />
          <ImageCaption>Jenni</ImageCaption>
        </div>
        <div>
          <img src={Derek} alt="Derek" />
          <ImageCaption>Derek</ImageCaption>
        </div>
        <div>
          <img src={Bill} alt="Bill" />
          <ImageCaption>Bill</ImageCaption>
        </div>
        <div>
          <img src={Allen} alt="Allen" />
          <ImageCaption>Allen</ImageCaption>
        </div>
        <div>
          <img src={Mark} alt="Mark" />
          <ImageCaption>Mark</ImageCaption>
        </div>
        <div>
          <img src={Bart} alt="Bart" />
          <ImageCaption>Bart</ImageCaption>
        </div>
        <div>
          <img src={Wade} alt="Wade" />
          <ImageCaption>Wade</ImageCaption>
        </div>
        <div>
          <img src={Vicki} alt="Vicki" />
          <ImageCaption>Vicki</ImageCaption>
        </div>
        <div>
          <img src={Alan} alt="Alan" />
          <ImageCaption>Alan</ImageCaption>
        </div>
        <div>
          <img src={Steven} alt="Steven" />
          <ImageCaption>Steven</ImageCaption>
        </div>
        <div>
          <img src={Ryan} alt="Ryan" />
          <ImageCaption>Ryan</ImageCaption>
        </div>
        <div>
          <img src={Tiffany} alt="Tiffany" />
          <ImageCaption>Tiffany</ImageCaption>
        </div>
      </ImageGrid>
      <h2 className="center" style={{ margin: '1rem 0' }}>
        More Members (Pictures Coming Soon!)
      </h2>
      <ol type="1" style={{ fontSize: '2.5rem' }}>
        <li>Josh Holley</li>
        <li>Keli Snow</li>
        <li>Nathan "Cardigan" Calhoun</li>
        <li>Abby Thomas</li>
        <li>Valerie Deason</li>
        <li>Jeremy "Jeeves" Butler</li>
      </ol>
    </>
  );
}
